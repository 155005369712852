/* eslint-disable camelcase */
import { pushToDataLayer } from '../pushToDataLayer';

/**
 *  DataLayer push on Campaign-image-container ctas and video click event.
 *  DataLayer push on CTA item.
 */
function clickOnCampaignImageContainer() {
  $('.ga-campaign-promotion').on('click', event => {
    pushToDataLayer({
      event: 'ga4_select_promotion',
      ga4_data: {
        creative_name: 'Campaign box',
        promotion_name: $(event.currentTarget).data('promotion-name')
      }
    });
  });

  $('.ga-banner-cta-promotion').on('click', event => {
    pushToDataLayer({
      event: 'ga4_select_promotion',
      ga4_data: {
        creative_name: 'Banner CTA',
        promotion_name: $(event.currentTarget).data('promotion-name')
      }
    });
  });
}

export const addCampaignListeners = () => {
  clickOnCampaignImageContainer();
};
