import { addGlobalListeners } from './listeners/globalListeners';
import { addArticleListeners } from './listeners/articleListeners';
import { addPageLoadEvents } from './listeners/pageLoadEvents';
import { addProductListeners } from './listeners/productListeners';
import { addPromoBoxListeners } from './listeners/promoBoxListeners';
import { addHeaderCTAListeners } from './listeners/headerCTAListener';
import { addHeroBannerListeners } from './listeners/heroBannerListeners';
import { addCampaignListeners } from './listeners/selectPromotionListeners';

export const addAnalyticsListeners = () => {
  addProductListeners();
  addArticleListeners();
  addPageLoadEvents();
  addGlobalListeners();
  addPromoBoxListeners();
  addHeaderCTAListeners();
  addHeroBannerListeners();
  addCampaignListeners();
};
