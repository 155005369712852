import { pushToDataLayer } from '../pushToDataLayer';

function externalLinkClick() {
	$(document).on('click', 'a[target=_blank]', event => {
		const url = event.currentTarget.href;
		const label = $.trim($(event.currentTarget).text());
		pushToDataLayer({ event: 'exit-link-new-window', label, url });
	});
}

function externalPopupVideoClick() {
	$('.js-video-youtube').on('click', event => {
		pushToDataLayer({
			event: 'engagement',
			action: 'video-play',
			url: $(event.currentTarget).attr('href')
		});
	})
}

export const addGlobalListeners = () => {
	externalLinkClick();
	externalPopupVideoClick();
};
