import { pushToDataLayer } from '../pushToDataLayer';
import { convertUrlToAbsolute } from './../../helpers/convertUrlToAbsolute';
import { getPathname } from './../../helpers/getPathname';

/**
 *  DataLayer push on header CTA event.
 */
function clickOnHeaderCTA() {
  $('.ga-header-item-link').on('click', event => {
    const eventAction = $(event.currentTarget).find('.title').text().trim();
    const isSameDomen = event.currentTarget.hostname === window.location.hostname;

    const eventLabel = isSameDomen
      ? getPathname(event.currentTarget)
      : convertUrlToAbsolute(event.currentTarget.href);

    pushToDataLayer({
      event: 'gaEvent',
      eventTrigger: 'headerNavigation',
      eventCategory: 'Header CTAs',
      eventAction,
      eventLabel
    });
  });
}

export const addHeaderCTAListeners = () => {
  clickOnHeaderCTA();
};